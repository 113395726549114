import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import isEmpty from "lodash/isEmpty"

import API from "@aws-amplify/api"
import { createContacto } from "../../graphql/mutations"

import PhoneInputField from "./phoneinput"

import "./contactform.scss"

export default class ContactForm extends React.Component {
  render() {
    return (
      <div className="container mb-5">
        <FormContent emailCallback={this.props.emailCallback} />
      </div>
    )
  }
}

ContactForm.defaultProps = {
  emailCallback: function () {},
}

ContactForm.propTypes = {
  emailCallback: PropTypes.func,
}

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Muy Corto!")
    .max(50, "Muy Largo!")
    .required("Requerido"),
  email: Yup.string()
    .email("Dirección de correo inválida")
    .required("Requerido"),
  message: Yup.string()
    .min(3, "Muy Corto!")
    .max(300, "Muy Largo!")
    .required("Requerido"),
  phone: Yup.string().required("Requerido"),
})

const FormContent = props => {
  const [value, setValue] = useState()
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
        phone: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={async info => {
        props.emailCallback("spinner", null, null)

        const name = info.name
        const email = info.email
        const message = info.message
        const phone = info.phone

        try {
          await API.graphql({
            query: createContacto,
            variables: {
              input: {
                name,
                email,
                message,
                phone,
              },
            },
          })
          props.emailCallback("success", info.name, info.email)
        } catch (e) {
          props.emailCallback("error")
        }
      }}
    >
      {({ errors, status, touched, dirty, isSubmitting }) => (
        <Form>
          <div className="row mb-lg-5">
            <div className="col-lg-6">
              <Field name="name" className="input-block">
                {({ field, form: { touched, errors } }) => {
                  const hasError =
                    errors[field.name] && touched[field.name] ? "hasError" : ""
                  return (
                    <div className="form-group">
                      <input
                        placeholder="Nombre Completo"
                        type="text"
                        className={hasError}
                        {...field}
                      />
                      <span
                        className={"form-icon fa-solid fa-user " + hasError}
                      ></span>
                    </div>
                  )
                }}
              </Field>
              <p className="hasError">
                <ErrorMessage name="name" />
              </p>

              <Field name="email" className="input-block">
                {({ field, form: { touched, errors } }) => {
                  const hasError =
                    errors[field.name] && touched[field.name] ? "hasError" : ""
                  return (
                    <div className="form-group">
                      <input
                        placeholder="Email"
                        type="text"
                        className={hasError}
                        {...field}
                      />
                      <span
                        className={"form-icon fa fa-envelope " + hasError}
                      ></span>
                    </div>
                  )
                }}
              </Field>
              <p className="hasError">
                <ErrorMessage name="email" />
              </p>

              <Field
                type="tel"
                name="phone"
                component={PhoneInputField}
                placeholder="Teléfono"
              />
            </div>

            <div className="col-lg-6">
              <Field name="message">
                {({ field, form: { touched, errors } }) => {
                  const hasError =
                    errors[field.name] && touched[field.name] ? "hasError" : ""
                  return (
                    <div className="form-group">
                      <textarea
                        type="text"
                        placeholder="Mensaje"
                        rows="5"
                        className={hasError}
                        {...field}
                      ></textarea>
                      <span
                        className={"form-icon fa-solid fa-pencil " + hasError}
                      ></span>
                    </div>
                  )
                }}
              </Field>
              <p className="hasError">
                <ErrorMessage name="message" />
              </p>
            </div>
          </div>
          {status && status.msg && <div>{status.msg}</div>}
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting || !isEmpty(errors) || !dirty}
            >
              Enviar Consulta
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
