import { useEffect, useState } from "react"

const useVisibility = (el = {}) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setIsVisible(entry.isIntersecting)
      })
    })
    observer.observe(el.current)
    return () => {}
  }, [el])

  return isVisible
}

export default useVisibility
