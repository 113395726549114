import React, { useLayoutEffect, useEffect, useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import NavBar from "../components/NavBar"
import ContactForm from "../components/ContactForm"
import EmailSuccess from "../components/EmailSuccess"
import Spinner from "../components/Spinner"
import ServerError from "../components/ServerError"
import Footer from "../components/Footer"

import Animated from "../components/Animated"
import useVisibility from "../components/SetVisibility"

import Amplify from "@aws-amplify/core"
import awsconfig from "../aws-exports"

Amplify.configure(awsconfig)

export const Head = () => (
  <>
    <title>Información, contacto paneles solares - Chile</title>
    <meta
      name="description"
      content="Envía tu pregunta y obtén más información a través de nuestro formulario de contacto"
    />
    <meta
      name="keywords"
      content="GlobalAxxis, Financiamiento, Credito, Credito Verde, Verde"
    />
  </>
)

const CreditoPage = props => {
  const [view, setView] = useState("contact")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const form = React.useRef()
  const [formHeight, setFormHeight] = useState("inherit")

  const ahorro_list = React.useRef()
  const isAhorroVisible = useVisibility(ahorro_list)

  useEffect(() => {
    setFormHeight(form.current.offsetHeight)
  })

  const emailCallback = (_view, _name, _email) => {
    setView(_view)
    setName(_name)
    setEmail(_email)
  }

  var template
  switch (view) {
    case "spinner":
      template = <Spinner />
      break
    case "success":
      template = (
        <EmailSuccess
          name={name}
          email={email}
          text={
            "¡Tu consulta ha sido enviada exitosamente!\nProntamente nos pondremos en contacto a tu correo"
          }
        />
      )

      // Google Ads conversion tracking
      //typeof window !== "undefined" &&
      //  typeof window.gtag !== "undefined" &&
      //  window.gtag("event", "conversion", {
      //    send_to: "AW-832693741/zMO0COT14dkDEO3Lh40D",
      //  })

      // Delta Digital Google Ads Campaign
      //typeof window !== "undefined" &&
      //  typeof window.gtag !== "undefined" &&
      //  window.gtag("event", "conversion", {
      //    send_to: "AW-10935866203/SrtoCOXj-N4DENum0N4o",
      //  })
      break
    case "error":
      template = <ServerError />
      break
    default:
      template = <ContactForm emailCallback={emailCallback} />
      break
  }

  return (
    <>
      <NavBar {...props} />
      <section id="creditoverde" className="body">
        <h1 style={{ color: "#6a6a6a" }}>
          ¡Bienvenidos a nuestra nueva alianza!
        </h1>
        <h3 style={{ color: "#6a6a6a" }}>
          Global Axxis y Coonfia se unen para ofrecer el mejor{" "}
          <span>Crédito Verde</span>
        </h3>

        <div className="row">
          <div className="col-md-4 offset-md-2 mt-5 mb-1 text-center">
            <div className="text-center">
              <div className="brandname">
                <div className="brandname-main">
                  Global<b>Axxis</b>
                </div>
                <div className="brandname-secondary">Energía Solar</div>
              </div>
            </div>
          </div>
          <div className="col-10 offset-1 col-md-4 offset-md-2 order-md-2 text-center">
            <StaticImage
              src="../img/credito-gal.jpg"
              alt="Global Axxis"
              placeholder="blurred"
              layout="fullWidth"
              aspectRatio={16 / 9}
              style={{ borderRadius: "5%" }}
            />
          </div>
          <div className="col-md-4 offset-md-2 order-md-4 text-center">
            <h4>Expertos en Proyectos Solares</h4>
            En Global Axxis te acompañamos en cada paso del camino. Desde los
            papeles hasta los permisos, nuestros expertos te asistirán en todos
            los aspectos de tue proyecto solar.
          </div>

          <div className="col-md-4 order-md-1 mt-5 mb-1 my-auto text-center">
            <StaticImage
              src="../img/coonfia-logo.png"
              alt="Coonfia Logo"
              placeholder="blurred"
              width={200}
              height={50}
            />
          </div>
          <div className="col-10 offset-1 col-md-4 offset-md-0 order-md-3 text-center">
            <StaticImage
              src="../img/credito-coonfia.jpg"
              alt="Coonfia"
              placeholder="blurred"
              layout="fullWidth"
              aspectRatio={16 / 9}
              style={{ borderRadius: "5%" }}
            />
          </div>
          <div className="col-md-4 order-md-5 text-center">
            <h4>Crédito Verde Conveniente</h4>
            Los créditos de Coonfia son simples y directos de ejecutar. Disfruta
            de un crédito aprobado manera rápida, eficiente y a tasas de mercado
            competitivas.
          </div>
        </div>
      </section>

      <section className="section-large my-5 row">
        <div className="col-md-4 offset-md-1 my-auto">
          <div className="ms-3">
            <h4>Los Mejores</h4>
            <h3>Beneficios</h3>
          </div>
          <ul className="list-group list-group-flush" ref={ahorro_list}>
            <Animated
              animationIn="slideInLeft"
              animationOut="fadeOut"
              isVisible={isAhorroVisible}
            >
              <li className="list-group-item">
                Descuento de hasta un 4,5% en tus paneles
              </li>
            </Animated>
            <Animated
              animationIn="slideInLeft"
              animationInDelay={300}
              animationOut="fadeOut"
              isVisible={isAhorroVisible}
            >
              <li className="list-group-item">Pago de 6 a 60 cuotas</li>
            </Animated>
            <Animated
              animationIn="slideInLeft"
              animationInDelay={600}
              animationOut="fadeOut"
              isVisible={isAhorroVisible}
            >
              <li className="list-group-item">
                Tasa de interés con 30% de descuento
              </li>
            </Animated>
            <Animated
              animationIn="slideInLeft"
              animationInDelay={900}
              animationOut="fadeOut"
              isVisible={isAhorroVisible}
            >
              <li className="list-group-item">
                60 días de gracia para el pago de la primera cuota
              </li>
            </Animated>
          </ul>
        </div>
        <div className="col-10 offset-1 col-md-6 offset-md-0">
          <StaticImage
            src="../img/savings.jpg"
            alt="Ahorro rentable con paneles solares"
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={4 / 3}
            style={{ borderRadius: "5%" }}
          />
        </div>
      </section>

      <div id="creditoverde_contacto" ref={form}>
        <div className="text-center">
          <h3 style={{ color: "#6a6a6a" }}>Pregunta por tu crédito verde</h3>
        </div>

        {template}
      </div>
      <Footer />
    </>
  )
}

export default CreditoPage
