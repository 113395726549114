import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "../OutboundLink.js"

export default class Footer extends React.Component {
  render() {
    return (
      <footer id="footer">
        <div className="footer_top">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-sm-6">
                <h3 className="menu_head">Nuestros Servicios</h3>
                <div className="footer_menu">
                  <ul className="footer-list">
                    <li>
                      <Link to="/residencial">Residencial</Link>
                    </li>
                    <li>
                      <Link to="/empresas">Empresas</Link>
                    </li>
                    <li>
                      <Link to="/agricultura">Agricultura</Link>
                    </li>
                    <li>
                      <Link to="/asesorias">Asesorías</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sm-6">
                <h3 className="menu_head">Conócenos</h3>
                <div className="footer_menu">
                  <ul className="footer-list">
                    <li>
                      <Link to="/about">Nosotros </Link>{" "}
                    </li>
                    <li>
                      <Link to="/contact">Contacto</Link>{" "}
                    </li>
                    <li>
                      <Link to="/miplanta">Empieza a Ahorrar</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <h3 className="menu_head">Contáctanos</h3>
                <div className="footer_menu_contact">
                  <ul className="footer-list">
                    <li className="fa_home">
                      <span> Camino Real 1454, Viña del Mar </span>
                    </li>
                    <li className="fa_phone">
                      <span> +56 32 3181847</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_b">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="footer_bottom">
                  <p className="text-block">
                    {" "}
                    <span>GlobalAxxis</span> &reg; <span>2022</span>
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="footer_mid float-end">
                  <div className="social-contact mt-4">
                    <OutboundLink href="https://www.facebook.com/globalaxxisrenewables/">
                      <i
                        className="fa-brands fa-facebook-f"
                        aria-label="Facebook"
                      ></i>
                    </OutboundLink>
                    {/*<li><a href="https://www.facebook.com/globalaxxisrenewables/">
                                <i className="fa fa-facebook" aria-label="Facebook"></i>
                            </a></li>
                            {/*<li> <a href="#"><i className="fa fa-twitter"></i></a></li>
                            <li> <a href="#"><i className="fa fa-rss"></i></a></li>
                            <li> <a href="#"><i className="fa fa-google-plus"></i> </a></li>
                            <li><a href="#"> <i className="fa fa-linkedin"></i></a></li>
                            <li><a href="#"> <i className="fa fa-pinterest"></i></a></li>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
